import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { ActiveRouteService } from './services/active-route.service';
import { Breakpoints } from './directives/media/breakpoints.enum';
import { Consents, CookieBannerDialogComponent } from './components/cookie-baner-dialog/cookie-banner-dialog.component';
import { NavigationCacheService } from './services/navigation-cache/navigation-cache.service';
import { SeoService } from './services/seo/seo.service';
import { SocialsConfig } from './services/seo/seo.model';
import { StateService } from './services/state/state.service';
import { SubComponent } from './components/utils/sub/sub.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends SubComponent implements OnInit {
  static readonly AUTHOR = 'Kamil Chmielowski';
  static readonly NAME = 'Apologetyka Biblijna';
  static readonly OFFSET = 120;

  protected readonly Breakpoints = Breakpoints;

  protected theme: 'light' | 'dark' = 'dark';

  constructor(private activeRouteService: ActiveRouteService,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private navigationCacheService: NavigationCacheService,
              private router: Router,
              private seoService: SeoService,
              private stateService: StateService,
              private viewportScroller: ViewportScroller,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    super();
    iconRegistry.addSvgIcon('logo', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/logo.svg'));
    iconRegistry.addSvgIconSetInNamespace('icons', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons.svg'));
  }

  ngOnInit(): void {
    this.viewportScroller.setOffset([0, AppComponent.OFFSET]);
    this.observeNavigationEnd();
    this.observeScrollEvent();
    this.fetchNavigationData();
    this.openCookieBanner();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
    this.stateService.changeTheme(theme);
  }

  private observeNavigationEnd(): void {
    this.subscription.add(
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        tap(() => this.initSidenavActiveOption()),
        mergeMap(route => route.data)
      ).subscribe((config: SocialsConfig) => {
        if (config['title']) {
          this.seoService.init(config);
        }
      })
    );
  }

  private observeScrollEvent(): void {
    this.subscription.add(this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (document.getElementById(e.anchor)) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    }));
  }

  private fetchNavigationData(): void {
    this.navigationCacheService.fetchData();
  }

  private openCookieBanner(): void {
    const consents: Consents = JSON.parse(localStorage.getItem('consents')) || undefined;

    if (!consents) {
      this.dialog.open<CookieBannerDialogComponent>(CookieBannerDialogComponent, { panelClass: 'mobile-full' });
    }
  }

  private initSidenavActiveOption(): void {
    const [, routeIndex, routeSubIndex] = location.pathname.split('/');
    const [, queryParams] = location.search.split('?');
    let index = ActiveRouteService.tableRoutes.findIndex(route => route.index === routeIndex);
    let subIndex = ActiveRouteService.tableRoutes[index]?.subIndex
      ?.findIndex(subIndex => subIndex === routeSubIndex || subIndex === queryParams);

    if (index < 0) {
      const aboutSubIndex = ActiveRouteService.aboutIndexes.findIndex(route => route === routeIndex);
      if (aboutSubIndex > -1) {
        index = ActiveRouteService.tableRoutes.length;
        subIndex = aboutSubIndex;
      }
    }
    setTimeout(() => this.activeRouteService.changeIndexes(index, subIndex), 0);
  }
}
